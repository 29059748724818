import React, { forwardRef, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import PageTitleWrapper from 'src/components/PageTitleWrapper';
import Footer from 'src/components/Footer';

import {
  Box,
  styled,
  Container,
  Card,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Typography,
  Button,
  FormHelperText,
  CircularProgress,
  TextField,
  Slide
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { serialize } from 'object-to-formdata';

import useCategory from 'src/hooks/useCategory';
import useSubCategory from 'src/hooks/useSubCategory';

import CheckCircleTwoToneIcon from '@mui/icons-material/CheckCircleTwoTone';
import { slugify } from 'src/utils/objectArray';

const SlideUp = forwardRef(function SlideUp(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const CategoryPage = styled(Box)(
  () => `
          display: flex; 
          flex-direction: column;
          width: 100%; 
      `
);

const SubCategoryUpdate = () => {
  const { categoryList, fetchCategoryList } = useCategory();
  const { updateSubCategory, subcategoryData } = useSubCategory();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();


  const controller = new AbortController();

  useEffect(() => {
    const fetchList = async () => {
      try {
        await fetchCategoryList(controller.signal);
      } catch (error) {
        console.log(error);
      }
    };

    fetchList();

    return () => controller.abort();
  }, []);

  const subCategoryStatus = (status, message) => {
    enqueueSnackbar(message, {
      variant: status ? 'success' : 'error',
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'right'
      },
      TransitionComponent: SlideUp
    });
  };

  return (
    <>
      <Helmet>
        <title>Update Sub Category</title>
      </Helmet>
      <PageTitleWrapper>
        <Typography variant="h1">{t('Update Sub Category')}</Typography>
      </PageTitleWrapper>
      <CategoryPage>
        <Container maxWidth="sm">
          <Card
            sx={{
              paddingY: 3,
              paddingX: 4,
              mt: 4
            }}
          >
            <Formik
              initialValues={{
                categoryId: subcategoryData?.categoryID,
                name: subcategoryData?.subcategoryName,
                subCategoryId: subcategoryData?._id,
                image: subcategoryData?.image,
                slug: subcategoryData?.slug,
                metaTitle: subcategoryData?.metaTitle,
                metaKeyword: subcategoryData?.metaKeyword,
                metaDescription: subcategoryData?.metaDescription,
                ogTitle: subcategoryData?.ogTitle,
                ogDescription: subcategoryData?.ogDescription,
                ogImage: subcategoryData?.ogImage,
              }}
              validationSchema={Yup.object().shape({
                categoryId: Yup.string().required('Please select a category'),
                name: Yup.string().required('Required'),
                slug: Yup.string().required('Required'),
                image: Yup.mixed().required('Please select an image'),
                metaTitle: Yup.string().required('Required'),
                metaKeyword: Yup.string().required('Required'),
                metaDescription: Yup.string().required('Required'),
                ogTitle: Yup.string().required('Required'),
                ogDescription: Yup.string().required('Required'),
              })}
              onSubmit={async (
                { name, 
                  image,
                  categoryId, 
                  subCategoryId, 
                  slug,
                  metaTitle,
                  metaKeyword,
                  metaDescription,
                  ogTitle,
                  ogDescription,
                  ogImage
                },
                { setSubmitting }
              ) => {
                try {
                  const serializeData = serialize({
                    name,
                    image,
                    categoryId,
                    subCategoryId,
                    slug,
                    metaTitle,
                    metaKeyword,
                    metaDescription,
                    ogTitle,
                    ogDescription,
                    ogImage,
                  });
                  const { success } = await updateSubCategory(serializeData);
                  setSubmitting(false);
                  if (success) {
                    subCategoryStatus(
                      true,
                      'The subcategory is successfully added'
                    );
                  } else {
                    subCategoryStatus(false, 'Failed to add subcategory');
                  }
                } catch (err) {
                  console.log(err);
                  setSubmitting(false);
                  subCategoryStatus(false, 'Failed to add subcategory');
                }
              }}
            >
              {({
                values,
                touched,
                errors,
                handleSubmit,
                handleChange,
                handleBlur,
                setFieldValue,
                isSubmitting
              }) => (
                <form onSubmit={handleSubmit}>
                  <FormControl fullWidth margin="normal">
                    <InputLabel id="select-label">Category</InputLabel>
                    <Select
                      value={values.categoryId}
                      id="categoryId"
                      label="categoryId"
                      onChange={(event) =>
                        setFieldValue('categoryId', event.target.value)
                      }
                    >
                      {categoryList.map(({ _id, name }) => (
                        <MenuItem key={_id} value={_id}>
                          {name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {Boolean(errors.categoryId) && (
                    <FormHelperText error>{errors.categoryId}</FormHelperText>
                  )}
                  <TextField
                    error={Boolean(touched.name && errors.name)}
                    fullWidth
                    margin="normal"
                    label="Name"
                    name="name"
                    type="text"
                    variant="outlined"
                    value={values.name}
                    //  onChange={handleChange}
                    onChange={(event) => {
                      const slug = slugify(event.target.value);
                      setFieldValue('slug', slug);
                      setFieldValue('name', event.target.value);
                    }}
                    onBlur={handleBlur}
                  />
                  {Boolean(touched.name && errors.name) && (
                    <FormHelperText error>{errors.name}</FormHelperText>
                  )}

                  <TextField
                    error={Boolean(touched.slug && errors.slug)}
                    fullWidth
                    margin="normal"
                    label="Slug"
                    name="slug"
                    type="text"
                    variant="outlined"
                    value={values.slug}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {Boolean(touched.slug && errors.slug) && (
                    <FormHelperText error>{errors.slug}</FormHelperText>
                  )}

                  <TextField
                    error={Boolean(touched.metaTitle && errors.metaTitle)}
                    fullWidth
                    margin="normal"
                    label="Meta Title"
                    name="metaTitle"
                    type="text"
                    variant="outlined"
                    value={values.metaTitle}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {Boolean(touched.metaTitle && errors.metaTitle) && (
                    <FormHelperText error>{errors.metaTitle}</FormHelperText>
                  )}
                  <TextField
                    error={Boolean(touched.metaKeyword && errors.metaKeyword)}
                    fullWidth
                    margin="normal"
                    label="Meta Keywords"
                    name="metaKeywords"
                    type="text"
                    variant="outlined"
                    value={values.metaKeyword}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {Boolean(touched.metaKeyword && errors.metaKeyword) && (
                    <FormHelperText error>{"Required"}</FormHelperText>
                  )}
                  <TextField
                    error={Boolean(touched.metaDescription && errors.metaDescription)}
                    fullWidth
                    margin="normal"
                    label="Meta Description"
                    name="metaDescription"
                    type="text"
                    variant="outlined"
                    value={values.metaDescription}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {Boolean(touched.metaDescription && errors.metaDescription) && (
                    <FormHelperText error>{errors.metaDescription}</FormHelperText>
                  )}
                  <TextField
                    error={Boolean(touched.ogTitle && errors.ogTitle)}
                    fullWidth
                    margin="normal"
                    label="Og Title"
                    name="ogTitle"
                    type="text"
                    variant="outlined"
                    value={values.ogTitle}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {Boolean(touched.ogTitle && errors.ogTitle) && (
                    <FormHelperText error>{errors.ogTitle}</FormHelperText>
                  )}
                  <TextField
                    error={Boolean(touched.ogDescription && errors.ogDescription)}
                    fullWidth
                    margin="normal"
                    label="Og Description"
                    name="ogDescription"
                    type="text"
                    variant="outlined"
                    value={values.ogDescription}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {Boolean(touched.ogDescription && errors.ogDescription) && (
                    <FormHelperText error>{errors.ogDescription}</FormHelperText>
                  )}
                  <FormControl fullWidth margin="normal">
                    <Button
                      color={values.image ? 'success' : 'primary'}
                      startIcon={
                        values.image ? <CheckCircleTwoToneIcon /> : null
                      }
                      size="large"
                      variant="outlined"
                      component="label"
                    >
                      Upload File
                      <input
                        type="file"
                        hidden
                        accept=".jpg, jpeg, .png"
                        onChange={(event) =>
                          setFieldValue('image', event.currentTarget.files[0])
                        }
                      />
                    </Button>
                    <Typography
                      sx={{
                        mt: 1
                      }}
                      variant="caption"
                    >
                      (.jpg, jpeg, .png)
                    </Typography>
                  </FormControl>
                  {Boolean(errors.image) && (
                    <FormHelperText error>{errors.image}</FormHelperText>
                  )}
                  <FormControl fullWidth margin="normal">
                    <Button
                      color={values.ogImage ? 'success' : 'primary'}
                      startIcon={
                        values.ogImage ? <CheckCircleTwoToneIcon /> : null
                      }
                      size="large"
                      variant="outlined"
                      component="label"
                    >
                      Upload Og Image
                      <input
                        type="file"
                        hidden
                        accept=".jpg, jpeg, .png"
                        onChange={(event) =>
                          setFieldValue('ogImage', event.currentTarget.files[0])
                        }
                      />
                    </Button>
                    <Typography
                      sx={{
                        mt: 1
                      }}
                      variant="caption"
                    >
                      (.jpg, jpeg, .png)
                    </Typography>
                  </FormControl>
                  {Boolean(errors.ogImage) && (
                    <FormHelperText error>{errors.ogImage}</FormHelperText>
                  )}
                  <Button
                    sx={{
                      mt: 2
                    }}
                    color="primary"
                    startIcon={
                      isSubmitting ? <CircularProgress size="1rem" /> : null
                    }
                    disabled={isSubmitting}
                    type="submit"
                    fullWidth
                    size="large"
                    variant="contained"
                  >
                    {t('Update Sub Category')}
                  </Button>
                </form>
              )}
            </Formik>
          </Card>
        </Container>
      </CategoryPage>
      <Footer />
    </>
  );
};

export default SubCategoryUpdate;
